export const translations = {
  en: {
    nav: {
      about: "About",
      services: "Services",
      portfolio: "Portfolio",
      contact: "Contact"
    },
    hero: {
      title: "Professional Services in the Netherlands",
    },
    about: {
      title: "About Me",
      description1: "Hello! I'm Destroyer, a male gigolo, based in the Netherlands. With 4 years of experience, I specialize in providing professional services to clients across the country.",
      description2: "My approach combines creativity, professionalism, and dedication to deliver exceptional results for every client.",
      highlights: {
        experience: "Extensive experience in high-end companionship",
        discretion: "Absolute discretion and confidentiality guaranteed",
        personality: "Charming personality with excellent conversation skills",
        fitness: "Dedicated fitness enthusiast with perfect physique"
      },
      stats: {
        years: "Years Experience",
        clients: "Satisfied Clients",
        gym: "Days/Week Gym",
        satisfaction: "Satisfaction Rate"
      }
    },
    services: {
      title: "My Services",
      service1: {
        title: "The Gentleman's Evening",
        description: "A 3-hour dinner date and conversation. Includes a complimentary bottle of wine."
      },
      service2: {
        title: "The Overnight Encounter",
        description: "An 8-hour overnight stay with breakfast in the morning. Includes a rose and a personalized note."
      },
      service3: {
        title: "The Weekend Getaway",
        description: "A 48-hour weekend together, including a dinner date and an activity of your choice (e.g., show, concert, or adventure). Includes a bouquet of flowers and chocolates."
      },
      includes: {
        companionship: "Professional companionship",
        dining: "Fine dining experience",
        conversation: "Engaging conversation",
        wine: "Complimentary wine",
        breakfast: "Breakfast included",
        rose: "Fresh rose",
        note: "Personal note",
        flowers: "Bouquet of flowers",
        chocolates: "Luxury chocolates",
        activity: "Activity of choice"
      }
    },
    contact: {
      title: "Contact Me",
      name: "Your Name",
      email: "Your Email",
      message: "Your Message",
      send: "Send Message",
      getInTouch: "Get in Touch"
    }
  },
  nl: {
    nav: {
      about: "Over Mij",
      services: "Diensten",
      portfolio: "Portfolio",
      contact: "Contact"
    },
    hero: {
      title: "Professionele Diensten in Nederland",
    },
    about: {
      title: "Over Mij",
      description1: "Hallo! Ik ben Destroyer, een mannelijke gigolo, gevestigd in Nederland. Met 4 jaar ervaring ben ik gespecialiseerd in het verlenen van professionele diensten aan klanten in het hele land.",
      description2: "Mijn aanpak combineert creativiteit, professionaliteit en toewijding om uitzonderlijke resultaten te leveren voor elke klant.",
      highlights: {
        experience: "Uitgebreide ervaring in hoogwaardig gezelschap",
        discretion: "Absolute discretie en vertrouwelijkheid gegarandeerd",
        personality: "Charmante persoonlijkheid met uitstekende gespreksvaardigheid",
        fitness: "Toegewijde fitness-enthousiasteling met perfect fysiek"
      },
      stats: {
        years: "Jaar Ervaring",
        clients: "Tevreden Klanten",
        gym: "Dagen/Week Gym",
        satisfaction: "Tevredenheid"
      }
    },
    services: {
      title: "Mijn Diensten",
      service1: {
        title: "De Gentleman's Avond",
        description: "Een 3-uur durende diner date en gesprek. Inclusief een gratis fles wijn."
      },
      service2: {
        title: "De Overnachting",
        description: "Een 8-uur durend verblijf met ontbijt in de ochtend. Inclusief een roos en een persoonlijk briefje."
      },
      service3: {
        title: "Het Weekend Arrangement",
        description: "Een 48-uur durend weekend samen, inclusief een diner date en een activiteit naar keuze (bijv. show, concert of avontuur). Inclusief een boeket bloemen en chocolade."
      },
      includes: {
        companionship: "Professioneel gezelschap",
        dining: "Fine dining ervaring",
        conversation: "Boeiende conversatie",
        wine: "Gratis fles wijn",
        breakfast: "Ontbijt inbegrepen",
        rose: "Verse roos",
        note: "Persoonlijk briefje",
        flowers: "Boeket bloemen",
        chocolates: "Luxe chocolade",
        activity: "Activiteit naar keuze"
      }
    },
    contact: {
      title: "Neem Contact Op",
      name: "Jouw Naam",
      email: "Jouw Email",
      message: "Jouw Bericht",
      send: "Verstuur Bericht",
      getInTouch: "Neem Contact Op"
    }
  }
}; 