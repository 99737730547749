import React, { useContext, useState } from 'react';
import { LanguageContext } from './contexts/LanguageContext';
import { translations } from './translations';
import './App.css';
import profilePlaceholder from './assets/profile-placeholder.jpg';
import workImage1 from './assets/work1-placeholder.jpg';
import workImage2 from './assets/work2-placeholder.jpg';

const LanguageSwitcher = () => {
  const { language, setLanguage } = useContext(LanguageContext);
  
  return (
    <div className="language-switcher">
      <button 
        className={`lang-btn ${language === 'en' ? 'active' : ''}`}
        onClick={() => setLanguage('en')}
      >
        EN
      </button>
      <button 
        className={`lang-btn ${language === 'nl' ? 'active' : ''}`}
        onClick={() => setLanguage('nl')}
      >
        NL
      </button>
    </div>
  );
};

const MobileMenuButton = ({ isOpen, toggleMenu }) => {
  return (
    <button className="mobile-menu-btn" onClick={toggleMenu}>
      {isOpen ? '✕' : '☰'}
    </button>
  );
};

function App() {
  const { language } = useContext(LanguageContext);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <div className="App">
      <LanguageSwitcher />
      <MobileMenuButton isOpen={isMobileMenuOpen} toggleMenu={toggleMobileMenu} />
      <header className="hero">
        <nav className={isMobileMenuOpen ? 'open' : ''}>
          <ul>
            <li><a href="#about">{translations[language].nav.about}</a></li>
            <li><a href="#services">{translations[language].nav.services}</a></li>
            <li><a href="#portfolio">{translations[language].nav.portfolio}</a></li>
            <li><a href="#contact">{translations[language].nav.contact}</a></li>
          </ul>
        </nav>
        <div className="hero-content">
          <img src={profilePlaceholder} alt="Profile" className="profile-image" />
          <h1>{translations[language].hero.title}</h1>
          <p>Customer Satisfaction is my top priority</p>
        </div>
      </header>

      {/* About Section */}
      <section id="about" className="section">
        <h2>{translations[language].about.title}</h2>
        <div className="about-content">
          <div className="about-text">
            <p>{translations[language].about.description1}</p>
            <p>{translations[language].about.description2}</p>
            <ul className="about-highlights">
              <li>{translations[language].about.highlights.experience}</li>
              <li>{translations[language].about.highlights.discretion}</li>
              <li>{translations[language].about.highlights.personality}</li>
              <li>{translations[language].about.highlights.fitness}</li>
            </ul>
          </div>
          <div className="about-stats">
            <div className="stat-card">
              <div className="stat-number">4+</div>
              <div className="stat-label">{translations[language].about.stats.years}</div>
            </div>
            <div className="stat-card">
              <div className="stat-number">100+</div>
              <div className="stat-label">{translations[language].about.stats.clients}</div>
            </div>
            <div className="stat-card">
              <div className="stat-number">6</div>
              <div className="stat-label">{translations[language].about.stats.gym}</div>
            </div>
            <div className="stat-card">
              <div className="stat-number">100%</div>
              <div className="stat-label">{translations[language].about.stats.satisfaction}</div>
            </div>
          </div>
        </div>
      </section>

      {/* Services Section */}
      <section id="services" className="section">
        <h2>{translations[language].services.title}</h2>
        <div className="services-grid">
          <div className="service-card">
            <h3>{translations[language].services.service1.title}</h3>
            <div className="service-duration">3 Hours</div>
            <p>{translations[language].services.service1.description}</p>
            <div className="service-includes">
              <ul>
                <li>{translations[language].services.includes.companionship}</li>
                <li>{translations[language].services.includes.dining}</li>
                <li>{translations[language].services.includes.conversation}</li>
                <li>{translations[language].services.includes.wine}</li>
              </ul>
            </div>
            <div className="service-price">€500</div>
          </div>

          <div className="service-card">
            <h3>{translations[language].services.service2.title}</h3>
            <div className="service-duration">8 Hours</div>
            <p>{translations[language].services.service2.description}</p>
            <div className="service-includes">
              <ul>
                <li>{translations[language].services.includes.companionship}</li>
                <li>{translations[language].services.includes.breakfast}</li>
                <li>{translations[language].services.includes.rose}</li>
                <li>{translations[language].services.includes.note}</li>
              </ul>
            </div>
            <div className="service-price">€1000</div>
          </div>

          <div className="service-card">
            <h3>{translations[language].services.service3.title}</h3>
            <div className="service-duration">48 Hours</div>
            <p>{translations[language].services.service3.description}</p>
            <div className="service-includes">
              <ul>
                <li>{translations[language].services.includes.companionship}</li>
                <li>{translations[language].services.includes.dining}</li>
                <li>{translations[language].services.includes.activity}</li>
                <li>{translations[language].services.includes.flowers}</li>
                <li>{translations[language].services.includes.chocolates}</li>
              </ul>
            </div>
            <div className="service-price">€2500</div>
          </div>
        </div>
      </section>

      {/* Portfolio Section */}
      <section id="portfolio" className="section">
        <h2>Portfolio</h2>
        <div className="portfolio-grid">
          <div className="portfolio-item">
            <img src={workImage1} alt="Work 1" />
            <div className="portfolio-overlay">
              <h3>Professionalism</h3>
              <p>Customer Satisfaction is my top priority</p>
            </div>
          </div>
          <div className="portfolio-item">
            <img src={workImage2} alt="Work 2" />
            <div className="portfolio-overlay">
              <h3>Big</h3>
              <p>I go to gym 6 times a week</p>
            </div>
          </div>
        </div>
      </section>

      {/* Contact Section */}
      <section id="contact" className="section">
        <h2>{translations[language].contact.title}</h2>
        <div className="contact-content">
          <form className="contact-form">
            <input type="text" placeholder={translations[language].contact.name} />
            <input type="email" placeholder={translations[language].contact.email} />
            <textarea placeholder={translations[language].contact.message}></textarea>
            <button type="submit">{translations[language].contact.send}</button>
          </form>
          <div className="contact-info">
            <h3>{translations[language].contact.getInTouch}</h3>
            <p>Email:contact@destroyer.nl.eu.org</p>
            <p>Phone: +31 XXXXXXXXX</p>
            <p>Location: Amstelveen, Netherlands</p>
          </div>
        </div>
      </section>

      <footer>
        <p>&copy; 2024 Destroyer. All rights reserved.</p>
      </footer>
    </div>
  );
}

export default App;
